
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as formulaireC5GSS0HFf4Meta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/formulaire.vue?macro=true";
import { default as indexhzZqJVrYgGMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/index.vue?macro=true";
import { default as _91k_93AgjuRz66FcMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/administration/acteurs/[k].vue?macro=true";
import { default as acteursyGRV2jv2LoMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/administration/acteurs.vue?macro=true";
import { default as configuration2f6PFDHO4rMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/administration/configuration.vue?macro=true";
import { default as formulaireLK98FVhH16Meta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/administration/territoires/[k]/formulaire.vue?macro=true";
import { default as indexkAkwg8bgfgMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/administration/territoires/[k]/index.vue?macro=true";
import { default as _91k_93pULGE5BXG2Meta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/administration/territoires/[k].vue?macro=true";
import { default as territoiresLACZDi20uSMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/administration/territoires.vue?macro=true";
import { default as utilisateurs_45permissionso9wSPhv5m1Meta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/administration/utilisateurs-permissions.vue?macro=true";
import { default as formulaireByxEn2pFBaMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/formulaire.vue?macro=true";
import { default as indexjLpQugKSVUMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/index.vue?macro=true";
import { default as _91k_93oKlPFxCdLdMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k].vue?macro=true";
import { default as utilisateursg0dihBad9zMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/administration/utilisateurs.vue?macro=true";
import { default as indexKbeo1rKgCAMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/connexion/index.vue?macro=true";
import { default as _91token_93hoJ68oaqGrMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue?macro=true";
import { default as indexm7sg4iCc1SMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue?macro=true";
import { default as mot_45de_45passe_45oubliesuy0ULYPYHMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie.vue?macro=true";
import { default as contact4Y7YLpDdwOMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/contact.vue?macro=true";
import { default as donnees_45personnellesZAYvphlPc4Meta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/donnees-personnelles.vue?macro=true";
import { default as _91k_93NcRs76AT8fMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/download/[k].vue?macro=true";
import { default as erreuraSynNf4blXMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/erreur.vue?macro=true";
import { default as formulairesHRSAHMZf5Meta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/formulaire.vue?macro=true";
import { default as indexjw2csql7BoMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/index.vue?macro=true";
import { default as _91k_93J84rMnRuBGMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k].vue?macro=true";
import { default as controles_45simultanessuzaOHBOYbMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/controles-simultanes.vue?macro=true";
import { default as formulairemKoSqqW5ZCMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/formulaire.vue?macro=true";
import { default as indexKWQK6bapzhMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/index.vue?macro=true";
import { default as _91k_934VZR7rFmwKMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k].vue?macro=true";
import { default as envoi_45de_45mailyAn8UsMDFMMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail.vue?macro=true";
import { default as historiqueKkQzqftXEcMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/historique.vue?macro=true";
import { default as formulairezYsGmMoQ5yMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/import/[k]/formulaire.vue?macro=true";
import { default as indexFoZc1N01KdMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/import/[k]/index.vue?macro=true";
import { default as _91k_937WSapmFjNpMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/import/[k].vue?macro=true";
import { default as importWSrVD8e1uVMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/import.vue?macro=true";
import { default as formulairevs7Z8bmAzLMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/formulaire.vue?macro=true";
import { default as indexySLmGRLFZqMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/index.vue?macro=true";
import { default as _91k_93g1LYMw5qmMMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k].vue?macro=true";
import { default as indisponibilites_45programmeesJCWSZrkDP3Meta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees.vue?macro=true";
import { default as formulaire7KROj47AqAMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/pei/[k]/formulaire.vue?macro=true";
import { default as indexS4y3IvbylZMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/pei/[k]/index.vue?macro=true";
import { default as _91k_93MfFnD0Xu0JMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/pei/[k].vue?macro=true";
import { default as peihvP96NRoMKMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/pei.vue?macro=true";
import { default as formulaire4WsktafpxTMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/formulaire.vue?macro=true";
import { default as indexO2NeGTgNDoMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/index.vue?macro=true";
import { default as _91k_93BnGrYcfUGPMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/tournees/[k].vue?macro=true";
import { default as tourneesW6VVohMvnCMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/tournees.vue?macro=true";
import { default as indexePfzGBde68Meta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/index.vue?macro=true";
import { default as indexFIsvBM5IXHMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/profil/index.vue?macro=true";
import { default as profileaxhPPzXoNMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/profil.vue?macro=true";
import { default as ressources3B8ChRT75dMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/ressources.vue?macro=true";
import { default as tableau_45de_45bordoTbGUDEaPNMeta } from "/builds/9141920290/hydraclic-v2/nuxt/pages/tableau-de-bord.vue?macro=true";
export default [
  {
    name: "administration-acteurs",
    path: "/administration/acteurs",
    meta: acteursyGRV2jv2LoMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/administration/acteurs.vue"),
    children: [
  {
    name: _91k_93AgjuRz66FcMeta?.name,
    path: ":k()",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/administration/acteurs/[k].vue"),
    children: [
  {
    name: "administration-acteurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/formulaire.vue")
  },
  {
    name: "administration-acteurs-k",
    path: "",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/administration/acteurs/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "administration-configuration",
    path: "/administration/configuration",
    meta: configuration2f6PFDHO4rMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/administration/configuration.vue")
  },
  {
    name: "administration-territoires",
    path: "/administration/territoires",
    meta: territoiresLACZDi20uSMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/administration/territoires.vue"),
    children: [
  {
    name: _91k_93pULGE5BXG2Meta?.name,
    path: ":k()",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/administration/territoires/[k].vue"),
    children: [
  {
    name: "administration-territoires-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/administration/territoires/[k]/formulaire.vue")
  },
  {
    name: "administration-territoires-k",
    path: "",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/administration/territoires/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "administration-utilisateurs-permissions",
    path: "/administration/utilisateurs-permissions",
    meta: utilisateurs_45permissionso9wSPhv5m1Meta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/administration/utilisateurs-permissions.vue")
  },
  {
    name: "administration-utilisateurs",
    path: "/administration/utilisateurs",
    meta: utilisateursg0dihBad9zMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/administration/utilisateurs.vue"),
    children: [
  {
    name: _91k_93oKlPFxCdLdMeta?.name,
    path: ":k()",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k].vue"),
    children: [
  {
    name: "administration-utilisateurs-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/formulaire.vue")
  },
  {
    name: "administration-utilisateurs-k",
    path: "",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/administration/utilisateurs/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "connexion",
    path: "/connexion",
    meta: indexKbeo1rKgCAMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/connexion/index.vue")
  },
  {
    name: mot_45de_45passe_45oubliesuy0ULYPYHMeta?.name,
    path: "/connexion/mot-de-passe-oublie",
    meta: mot_45de_45passe_45oubliesuy0ULYPYHMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie.vue"),
    children: [
  {
    name: "connexion-mot-de-passe-oublie-token",
    path: ":token()",
    meta: _91token_93hoJ68oaqGrMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/[token].vue")
  },
  {
    name: "connexion-mot-de-passe-oublie",
    path: "",
    meta: indexm7sg4iCc1SMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/connexion/mot-de-passe-oublie/index.vue")
  }
]
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/contact.vue")
  },
  {
    name: "donnees-personnelles",
    path: "/donnees-personnelles",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/donnees-personnelles.vue")
  },
  {
    name: "download-k",
    path: "/download/:k()",
    meta: _91k_93NcRs76AT8fMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/download/[k].vue")
  },
  {
    name: "erreur",
    path: "/erreur",
    meta: erreuraSynNf4blXMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/erreur.vue")
  },
  {
    name: "gestion-controles-simultanes",
    path: "/gestion/controles-simultanes",
    meta: controles_45simultanessuzaOHBOYbMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/controles-simultanes.vue"),
    children: [
  {
    name: _91k_93J84rMnRuBGMeta?.name,
    path: ":k()",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k].vue"),
    children: [
  {
    name: "gestion-controles-simultanes-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/formulaire.vue")
  },
  {
    name: "gestion-controles-simultanes-k",
    path: "",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/controles-simultanes/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-envoi-de-mail",
    path: "/gestion/envoi-de-mail",
    meta: envoi_45de_45mailyAn8UsMDFMMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail.vue"),
    children: [
  {
    name: _91k_934VZR7rFmwKMeta?.name,
    path: ":k()",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k].vue"),
    children: [
  {
    name: "gestion-envoi-de-mail-k-formulaire",
    path: "formulaire",
    meta: formulairemKoSqqW5ZCMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/formulaire.vue")
  },
  {
    name: "gestion-envoi-de-mail-k",
    path: "",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/envoi-de-mail/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-historique",
    path: "/gestion/historique",
    meta: historiqueKkQzqftXEcMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/historique.vue")
  },
  {
    name: "gestion-import",
    path: "/gestion/import",
    meta: importWSrVD8e1uVMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/import.vue"),
    children: [
  {
    name: _91k_937WSapmFjNpMeta?.name,
    path: ":k()",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/import/[k].vue"),
    children: [
  {
    name: "gestion-import-k-formulaire",
    path: "formulaire",
    meta: formulairezYsGmMoQ5yMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/import/[k]/formulaire.vue")
  },
  {
    name: "gestion-import-k",
    path: "",
    meta: indexFoZc1N01KdMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/import/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-indisponibilites-programmees",
    path: "/gestion/indisponibilites-programmees",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees.vue"),
    children: [
  {
    name: _91k_93g1LYMw5qmMMeta?.name,
    path: ":k()",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k].vue"),
    children: [
  {
    name: "gestion-indisponibilites-programmees-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/formulaire.vue")
  },
  {
    name: "gestion-indisponibilites-programmees-k",
    path: "",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/indisponibilites-programmees/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-pei",
    path: "/gestion/pei",
    meta: peihvP96NRoMKMeta || {},
    alias: ["/gestion/carte"],
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/pei.vue"),
    children: [
  {
    name: _91k_93MfFnD0Xu0JMeta?.name,
    path: ":k()",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/pei/[k].vue"),
    children: [
  {
    name: "gestion-pei-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/pei/[k]/formulaire.vue")
  },
  {
    name: "gestion-pei-k",
    path: "",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/pei/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "gestion-tournees",
    path: "/gestion/tournees",
    meta: tourneesW6VVohMvnCMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/tournees.vue"),
    children: [
  {
    name: _91k_93BnGrYcfUGPMeta?.name,
    path: ":k()",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/tournees/[k].vue"),
    children: [
  {
    name: "gestion-tournees-k-formulaire",
    path: "formulaire",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/formulaire.vue")
  },
  {
    name: "gestion-tournees-k",
    path: "",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/gestion/tournees/[k]/index.vue")
  }
]
  }
]
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/index.vue")
  },
  {
    name: profileaxhPPzXoNMeta?.name,
    path: "/profil",
    meta: profileaxhPPzXoNMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/profil.vue"),
    children: [
  {
    name: "profil",
    path: "",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/profil/index.vue")
  }
]
  },
  {
    name: "ressources",
    path: "/ressources",
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/ressources.vue")
  },
  {
    name: "tableau-de-bord",
    path: "/tableau-de-bord",
    meta: tableau_45de_45bordoTbGUDEaPNMeta || {},
    component: () => import("/builds/9141920290/hydraclic-v2/nuxt/pages/tableau-de-bord.vue")
  }
]